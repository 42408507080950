import * as img from 'img';
import { Dashboard, DemoInfraDashboardVariant, GrafanaDashboardVariant } from 'types/types.dashboard';

const grafanaDashboardUrl = '/dashboards/f/grafana-demodashboards';

export const demoInfraSources: string[] = [
  'grafanacloud-demoinfra-logs',
  'grafanacloud-demoinfra-traces',
  'grafanacloud-demoinfra-prom',
  'grafanacloud-demoinfra-profiles',
];

export const dashboards: Dashboard[] = [
  {
    variant: DemoInfraDashboardVariant.QuickPizza,
    key: DemoInfraDashboardVariant.QuickPizza,
    screenshot: img.qp,
    title: '🍕 QuickPizza SRE Demo',
    description:
      "Experience Grafana Cloud's powerful monitoring features through this interactive demo, designed to showcase how you can effectively monitor a running application. Here's what you'll get to explore",
    features: [
      'A fully instrumented web application showcasing business and application metrics',
      'Comprehensive database statistics, real - time response metrics for in-depth exploration',
      'Learn efficient monitoring methods for running applications with advanced dashboard functionalities',
    ],
    redirectUrl: `${grafanaDashboardUrl}-${DemoInfraDashboardVariant.QuickPizza}`,
    checkFolder: true,
  },
  {
    variant: DemoInfraDashboardVariant.Weather,
    key: DemoInfraDashboardVariant.Weather,
    screenshot: img.weather,
    title: '⛅ Weather Demo',
    description: "This demo showcases Grafana's flexibility by visualizing non-technical data. It includes:",
    features: [
      'Live, real-time weather data from diverse locations worldwide',
      'Historical weather data',
      'Forecast information',
    ],
    redirectUrl: (ctx) =>
      ctx.region.indexOf('-us-') !== -1
        ? `/d/19f285e5-c42d-4007-b671-31d493686316` // fahrenheit
        : `/d/c1385932-e8b5-481e-a999-f716359f8cf2`, // celsius
    checkFolder: true,
  },
  {
    variant: DemoInfraDashboardVariant.QuickPizza || DemoInfraDashboardVariant.Weather,
    key: GrafanaDashboardVariant.AppO11y,
    screenshot: img.appO11y,
    title: 'Application Observability Demo',
    description:
      'Explore Application Observability in Grafana Cloud with our demo. See firsthand how to visualize and manage 5 services, including 4 Go services and one PostgreSQL, all instrumented with OpenTelemetry (Otel):',
    features: [
      "Get a clear view of each application's performance with tailored dashboards",
      'Understand the relationships and communications between the services at a glance',
      'Gain valuable insights from trace data to streamline debugging and refine application performance efficiently',
    ],
    redirectUrl: '/a/grafana-app-observability-app',
    openHook: () => {
      localStorage.setItem('grafana-app-observability-app-ds-loki', 'grafanacloud-demoinfra-logs');
      localStorage.setItem('grafana-app-observability-app-ds-tempo', 'grafanacloud-demoinfra-traces');
      localStorage.setItem('grafana-app-observability-app-ds-prometheus', 'grafanacloud-demoinfra-prom');
      localStorage.setItem(
        'grafana-app-observability-app-ds-grafana-pyroscope-datasource',
        'grafanacloud-demoinfra-profiles'
      );
    },
    forceRefresh: true,
  },
  {
    variant: DemoInfraDashboardVariant.QuickPizza || DemoInfraDashboardVariant.Weather,
    key: GrafanaDashboardVariant.K8,
    screenshot: img.k8,
    title: 'Kubernetes Monitoring Demo',
    description:
      'Explore our dynamic 2-node Kubernetes cluster demo to discover how our Kubernetes monitoring solution enhances visibility and control:',
    features: [
      'Get an overview of cluster information and drill down to see specific container-level information',
      "Find out how to use just the right amount of resources, so you don't spend more than you need",
      "Compare resource usage to tweak and optimize your cluster's performance for peak efficiency",
    ],
    redirectUrl:
      '/a/grafana-k8s-app/home?var-datasource=grafanacloud-demoinfra-prom&var-loki=grafanacloud-demoinfra-logs',
  },
];
